import React, { useState } from "react";

const Appointment = () => {
  const [formData, setFormData] = useState({
    country: "",
    counselor: "",
    name: "",
    email: "",
    date: "",
    time: ""
  });
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://backend.ishahelpline.org/appointment.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        setMessage(data.message);
        if (data.status === "success") {
          setShowPopup(true); 
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <section className="appointment">
        <div className="container-fluid bg-primary my-5 py-5" id='appointment-container'>
          <div className="container py-5">
            <div className="row gx-5">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="mb-4">
                  <h5 className="d-inline-block text-white text-uppercase border-bottom">Appointment</h5>
                  <h1 className="display-4">Make An Appointment</h1>
                </div>
                <p className="text-white mb-5">Schedule your consultation today to embark on your journey towards exceptional caregiving. Our team is ready to assist you in selecting the perfect training program tailored to your needs and aspirations.</p>
                <a className="btn btn-dark rounded-pill py-3 px-5 me-3" href="/contact">Contact Us</a>
              </div>
              <div className="col-lg-6">
                <div className="bg-white text-center rounded p-5">
                  <h1 className="mb-4">Book An Appointment</h1>
                  <form onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-12 col-sm-6">
                        <select 
                          className="form-select bg-light border-0" 
                          style={{height: '55px'}}
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        >
                          <option value="" disabled selected>Select Country</option>
                          <option value="Australia">Australia</option>
                          <option value="UK">UK</option>
                          <option value="USA">USA</option>
                          <option value="Israel">Israel</option>
                          <option value="Germany">Germany</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div className="col-12 col-sm-6">
                        <select 
                          className="form-select bg-light border-0" 
                          style={{height: '55px'}}
                          name="counselor"
                          value={formData.counselor}
                          onChange={handleChange}
                          required
                        >
                          <option value="" disabled selected>Select Counselor</option>
                          <option value="Indira Sharma">Indira Sharma</option>
                          <option value="Priyanshu Adhikari">Priyanshu Adhikari</option>
                          <option value="Nisha Shrestha">Nisha Shrestha</option>
                        </select>
                      </div>
                      <div className="col-12 col-sm-6">
                        <input 
                          type="text" 
                          className="form-control bg-light border-0" 
                          placeholder="Your Name" 
                          style={{height: '55px'}}
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input 
                          type="email" 
                          className="form-control bg-light border-0" 
                          placeholder="Your Email" 
                          style={{height: '55px'}}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input 
                          type="date" 
                          className="form-control bg-light border-0" 
                          placeholder="Date" 
                          style={{height: '55px'}}
                          name="date"
                          value={formData.date}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input 
                          type="time" 
                          className="form-control bg-light border-0" 
                          placeholder="Time" 
                          style={{height: '55px'}}
                          name="time"
                          value={formData.time}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <button 
                          className="btn btn-primary w-100 py-3" 
                          type="submit" 
                          id='appointment-button'
                        >
                          Make An Appointment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showPopup && (
  <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-75">
    <div className="bg-white p-8 rounded shadow-md duration-300 hover:scale-105 hover:shadow-xl">
      <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto mt-8 h-16 w-16 text-green-400" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
      </svg>
      <h1 className="mt-2 text-center text-2xl font-bold text-gray-500">Success</h1>
      <p className="my-4 text-center text-sm text-gray-500">{message}</p>
      <div className="space-x-4 bg-gray-100 py-4 text-center">
        <button className="inline-block rounded-md bg-green-500 px-6 py-2 font-semibold text-green-100 shadow-md duration-75 hover:bg-green-400" onClick={() => setShowPopup(false)}>Okay</button>
      </div>
    </div>
  </div>
)}
    </>
  );
};

export default Appointment;
